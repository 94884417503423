import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import {  MouseEvent } from 'react'
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  selectedAction: string | null;
  searchText: string,
  brandAnchorEl: HTMLElement | null;
  value: number | 0;
  CartitemList: {
    id: string;
    type: string;
    attributes: {
        product_id: number;
        quantity: number;
        price: number;
        total_price: number;
        rfq_name: string;
        cart_id: number;
        product_name: string;
        product_image: {
            url: string;
        };
        unit_of_measurement: string;
        brand_name: string;
        upid_id: number;
        item_count: number;
    };
}[];
  quantity: number,
  defaultQuantity: number,
  itemid: number,
  cartItemId:string| number|null

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCartItemList: string = "";
  deletemycartItem: string= "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
      sidebarToggle: false,
      openDialog: true,
      activeComponent: "Company",
      anchorEl: null,
      selectedAction: null,
      searchText: '',
      brandAnchorEl: null,
      value: 0,
      CartitemList: [],
      quantity: 20,
      defaultQuantity: 20, 
      itemid: 0,
      cartItemId:null
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
     await super.componentDidMount(); 
     this.getAllCartItems();
// Customizable Area End
  }

  // Customizable Area Start
  receive = async (from: String, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson?.error) {
        this.parseApiErrorResponse(responseJson.error);
        this.parseApiCatchErrorResponse(responseJson.error);
        return;
      }
  
      const responseHandlers = {
        [this.getAllCartItemList]: () => {
          this.setState({ CartitemList: responseJson.data || [] });
        },
        [this.deletemycartItem]: () => {
          this.setState({ cartItemId: null },() => {
            this.getAllCartItems();
          });
        }
      };
      if (responseHandlers[apiRequestCallId]) {
        responseHandlers[apiRequestCallId]();
      }
    }
  };
  deleteMyCartItem = async(cartItemId:string|number|null) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('login-token'),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deletemycartItem = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteApiMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.deletemycartItem}?id=${cartItemId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDelete = () => {
    const { cartItemId } = this.state; 
    if (cartItemId) {
      this.setState({ anchorEl: null,  }); 
      this.deleteMyCartItem(cartItemId); 
    }
  };
  getAllCartItems = async() => { 
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('login-token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllCartItemList = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getcartItems}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
 
    handleMenuClick = (
      event: MouseEvent<HTMLElement>,
      action: string,
      itemId:string
    ): void => {      
      this.setState({ anchorEl: event.currentTarget, selectedAction: action , cartItemId:itemId });
    };
    
    handleMenuClose = (): void => {
      this.setState({ anchorEl: null });
    };
    
    handleBrandMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
      this.setState({ brandAnchorEl: event.currentTarget });
    };
    
    handleBrandMenuClose = (): void => {
      this.setState({ brandAnchorEl: null });
    };
    
    handleTabChange = (newValue: number): void => {
      this.setState({ value: newValue});
    }
  // Customizable Area End
}
